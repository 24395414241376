define("suppliers/models/ad-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string')
  });
});