define("suppliers/serializers/ad-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'guest_token',
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.decamelize(attr);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      delete json.company_name;
      return json;
    }
  });
});