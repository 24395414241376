define("suppliers/adapters/application", ["exports", "ember-inflector", "ember-data", "suppliers/config/environment"], function (_exports, _emberInflector, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.apiURI,
    namespace: 'suppliers',
    headers: {
      'Accept': 'application/vnd.gohiring+json;version=v1'
    },
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    }
  });
});